import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import JitterBug from './_elements/jitter-bug.js';
import MakeConfetti from './_elements/make-confetti.js';
// import './_utilities/scroll-timeline.js';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {
  // problem: productivity
  gsap.to('.grind', {
    scrollTrigger: {
      trigger: '.problem-productivity',
      scrub: true,
      start: '-30%',
      end: '90%',
    },
    duration: 1,
    y: '0%',
  });

  gsap.to('.shredder-project', {
    scrollTrigger: {
      trigger: '.problem-reason',
      scrub: true,
      start: '0%',
      end: '70%',
    },
    duration: 1,
    y: '100%',
  });

  // solution: intro
  const solutionIntroOverlay = gsap.timeline({
    scrollTrigger: {
      trigger: '.solution-intro',
      start: '0%',
      end: '65% center',
      scrub: true,
    },
  });

  solutionIntroOverlay.from('.steady-hero-overlay', { opacity: 0 })
    .to('.steady-hero-overlay', { opacity: 1, duration: 2 })
    .to('.steady-hero-overlay', { opacity: 1, duration: 40 })
    .to('.steady-hero-overlay', { opacity: 0, duration: 1 });

  gsap.to('.steady-hero', {
    scrollTrigger: {
      trigger: '.solution-intro',
      scrub: true,
      start: '25% center',
      end: '30%',
    },
    duration: 1,
    opacity: 1,
    scale: 1,
  });

  // solution: productivity
  const spPie = gsap.timeline({
    scrollTrigger: {
      trigger: '.solution-productivity',
      start: '20% bottom',
      end: '60% center',
      scrub: true,
    },
  });

  spPie.from('.pie-filling.--quo', { '--percentage': '0%', rotation: 0 })
    .to('.pie-filling.--quo', { '--percentage': '80%', rotation: 90, duration: 1 });

  const spPieAlt = gsap.timeline({
    scrollTrigger: {
      trigger: '.solution-productivity',
      start: '20% bottom',
      end: '60% center',
      scrub: true,
    },
  });

  spPieAlt.from('.pie-filling.--alternate', { '--percentage': '0%', rotation: 0 })
    .to('.pie-filling.--alternate', { '--percentage': '25%', rotation: 90, duration: 1 });

  gsap.to('.pie-label', {
    scrollTrigger: {
      trigger: '.solution-productivity',
      start: '65% bottom',
      end: '66% center',
      scrub: true,
    },
    duration: 1,
    opacity: 1,
    translateX: 0,
  });

  // solution: energy
  gsap.from('.bar .fill', {
    scrollTrigger: {
      trigger: '.solution-energy',
      start: '20% bottom',
      end: '50% center',
      scrub: true,
    },
    duration: 1,
    height: '0%',
  });
});
